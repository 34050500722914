import { ref, reactive, nextTick } from 'vue';
import * as API from '@/API/rv/rvSicco';
import { findBrandByBu } from '@/API/rv/rvDealer';
import { SiccoStatusData, SiccoOverviewData, SiccoTableData } from '@/views/RetailValidation/Sicco/types';
import { getDealerName } from '@/API/rv/rvSicco';
import setColumnWidth from '@/utils/rv/setColumnWidth';
import getMonthLast from '@/utils/rv/getMonthLast';
import cleanEmptyEleArray from '@/utils/rv/cleanEmptyElement';
import downloadFile from '@/utils/rv/downloadFile';
import { resetParam, resetPatrial } from '@/utils/rv/configParam';
import { message } from 'ant-design-vue';
import { useStore } from 'vuex';
const useSiccoOverview = () => {
  const { state } = useStore();
  const buIdsArray: number[] = state.user.buList.map((item: any) => {
    return item.id;
  });
  let allBrands: string[] = [];
  // 打开筛选条件
  const isOpen = ref<boolean>(false);
  const activeKey = ref<string>('');
  const tableData = ref<SiccoTableData[]>([]);
  const total = ref<number>(0);
  const input = ref();
  // 模糊查询4个接口
  const dealerNameEnArray = ref<any>([]);
  const swtArray = ref<any>([]);
  const coficoArray = ref<any>([]);
  const dealerNameCnArray = ref<any>([]);
  // 批量查询
  const batchQuery = reactive({
    batchQueryTitle: '',
    batchQueryModal: false,
    batchQueryListTitle: ''
  });
  const approvalStatusArray = ref<SiccoStatusData[]>([]);
  // 激励适用性
  const checkStatusArray = ref<SiccoStatusData[]>([]);
  const cSelect = ref();
  // 模糊查询是否显示下拉框
  const flagBlurSearch = reactive({
    showCFlag: false,
    flagCName: ''
  });
  // 多维查询下拉框备选项
  const selectData = reactive({
    areasArray: [],
    powerTrainArray: [],
    modelYearArray: [],
    typeClassArray: []
  });
  const columns = reactive([
    { title: '车架号', dataIndex: 'vinNo', key: 'vinNo', width: 168, fixed: 'left' },
    { title: '车型', dataIndex: 'model', key: 'model', width: 80 },
    { title: '品牌', dataIndex: 'brand', width: 80 },
    { title: '经销商中文名称', dataIndex: 'dealerNameCn', width: 180 },
    { title: '经销商代码(Cofico)', dataIndex: 'dealerCofficoCode', width: 180 },
    { title: '经销商代码(SWT)', dataIndex: 'dealerSwtCode', width: 150 },
    { title: '区域', dataIndex: 'region', width: 80, key: 'region' },
    { title: '客户名称', dataIndex: 'customerName', width: 100, key: 'customerName' },
    { title: '零售类型', dataIndex: 'usage', width: 110, key: 'usage' },
    { title: '零售日期', dataIndex: 'retailDate', key: 'retailDate', width: 90 },
    { title: '特殊车辆用途', dataIndex: 'specialUsage', key: 'specialUsage', width: 100 },
    { title: '提交状态', dataIndex: 'approlStatus', width: 100, slots: { customRender: 'approlStatus' } },
    {
      title: '激励适用性',
      dataIndex: 'checkStatus',
      key: 'checkStatus',
      width: 116,
      slots: { customRender: 'checkStatus' }
    },
    { title: '文件', key: 'file', fixed: 'right', width: 200, slots: { customRender: 'file' } },
    {
      title: '操作',
      key: 'operation',
      fixed: 'right',
      width: 60,
      slots: { customRender: 'action' }
    }
  ]);
  // 收起按钮显示位置 false 在折叠面板的header
  const showCollapsed = ref<boolean>(false);
  // 打开筛选条件面板
  const isOpenPanle = ref<boolean>(false);
  const overview = ref<SiccoOverviewData>({
    currRetailMonth: '',
    currRetailSubmitDate: '',
    currRetailTotal: '',
    currPreCheckTotal: '',
    currMdfPreCheckTotal: '',
    currVinCountOfReturn: '',
    lastRetailMonth: '',
    lastRetailSubmitDate: '',
    lastRetailTotal: '',
    lastPreCheckTotal: '',
    lastMdfPreCheckTotal: '',
    lastVinCountOfReturn: '',
    abnormalRetailTotal: '',
    abnormalPreCheckTotal: '',
    abnormalMdfPreCheckTotal: '',
    abnormalVinCountOfReturn: ''
  });
  const getRegionsbyBulist = (bulist: number[]) => {
    API.getRegionsbyBuList({ params: { buIds: bulist } }).then((res: any) => {
      const regionArray: any = [];
      /*if(res.code=== '0'){
        res.data.forEach((item: any)=>{
          if(item.regionDto?.length>0){
            item.regionDto.forEach((itemCld: any)=>{
              const obj = Object.assign({})
              obj.key = item?.id+'_'+itemCld?.id
              obj.code = item?.id+'_'+itemCld?.id
              obj.msg = item?.nameEn+'_'+itemCld?.regionEn
              regionArray.push(obj)
            })
          }
          else{
            const obj = Object.assign({})
            obj.key = item.id+'_'
            obj.code = item.id+'_'
            obj.msg = item.nameEn+'_'
            regionArray.push(obj)
          }
        })
        selectData.areasArray = regionArray;
        //console.log('selectData 区域=====',selectData.areasArray)
      }
      else{
        message.error(res.message)
      }*/
      if (res.code === '0') {
        const keys = new Set<string>();
        res.data.forEach((item: any) => {
          item.regionDto.forEach((itemCld: any) => {
            const key = `${item?.id}_${itemCld?.id}`;
            if (!keys.has(key)) {
              regionArray.push({
                key,
                code: key,
                msg: `${item?.nameEn}_${itemCld?.regionEn}_${itemCld?.subRegionEn}`
              });
              keys.add(key);
            }
          });
        });
        selectData.areasArray = regionArray;
      } else {
        message.error(res.message);
      }
    });
  };

  // 筛选条件
  const queryParams = reactive({
    vinNo: '',
    model: '',
    retailStartDate: '',
    retailEndDate: '',
    usage: '',
    commissionNo: '',
    typeClass: '',
    modelYear: '',
    entity: '',
    specialUsage: '',
    powerTrain: '',
    invoiceStartDate: '',
    invoiceEndDate: '',
    licenseStartDate: '',
    licenseEndDate: '',
    approlStatus: '',
    brand: '',
    checkStatus: '',
    dealerNameCn: '',
    dealerCofficoCode: '',
    dealerNameEn: '',
    dealerSwtCode: '',
    region: '',
    vinNos: [''],
    commissionNos: [''],
    brandList: [] as string[],
    current: 1,
    size: 20,
    // 点击页面上的统计区域的标志
    statisticsClicked: 0,
    // 点击页面上的异常统计区域的标志
    abnormalAreaClicked: 0
  });

  const init = async () => {
    const brandsData = (await findBrandByBu(buIdsArray)) as any;
    const brands = new Map();
    for (const buBrands of brandsData) {
      for (const brand of buBrands.brandList) {
        if (!brands.has(brand.id)) {
          brands.set(brand.id, brand);
        }
      }
    }
    allBrands = Array.from(brands.values()).map((item: { brandNameEn: string }) => item.brandNameEn);

    API.getSiccoOverview(queryParams.brand ? [queryParams.brand] : allBrands).then((res) => {
      overview.value = res;
    });
    API.getApprovalStatus().then((res) => {
      approvalStatusArray.value = res;
      approvalStatusArray.value.push({ code: '全部', msg: '全部' }); // '全部' 根据story添加一个状态
    });
    API.getCheckStatus().then((res) => {
      checkStatusArray.value = res;
      // '全部' 根据story添加一个状态
      checkStatusArray.value.push({ code: '全部', msg: '全部' });
    });
    API.getSelectData().then((res: any) => {
      const newModelYearArray: any = [];
      const newpowerTrainArray: any = [];
      const newtypeClassArray: any = [];
      const newareasArray: any = [];
      if (res.modelYear) {
        res.modelYear.forEach((item: any) => {
          if (item.msg !== '' && item.code !== '') {
            newModelYearArray.push(item);
            selectData.modelYearArray = newModelYearArray;
          }
        });
      }
      if (res.powerTrain) {
        res.powerTrain.forEach((item: any) => {
          if (item.msg !== '' && item.code !== '') {
            newpowerTrainArray.push(item);
            selectData.powerTrainArray = newpowerTrainArray;
          }
        });
      }
      if (res.typeClass) {
        res.typeClass.forEach((item: any) => {
          if (item.msg !== '' && item.code !== '') {
            newtypeClassArray.push(item);
            selectData.typeClassArray = newtypeClassArray;
          }
        });
      }
      if (res.salesRegion) {
        res.salesRegion.forEach((item: any) => {
          if (item.msg !== '' && item.code !== '') {
            newareasArray.push(item);
            selectData.areasArray = newareasArray;
          }
        });
      }
    });
    findBrandByBu(buIdsArray).then((res: any) => {
      const brands = new Map();
      for (const buBrands of res) {
        for (const brand of buBrands.brandList) {
          if (!brands.has(brand.id)) {
            brands.set(brand.id, brand);
          }
        }
      }
      allBrands = Array.from(brands.values()).map((item: { brandNameEn: string }) => item.brandNameEn);
    });
    getRegionsbyBulist(buIdsArray);
  };

  const resetEvent = () => {
    resetPatrial(queryParams);
    queryParams.vinNos = [''];
    queryParams.commissionNos = [''];
    (queryParams.current = 1), (queryParams.size = 20);
  };
  const getTableData = () => {
    queryParams.vinNo = queryParams.vinNo.trim();
    queryParams.commissionNo = queryParams.commissionNo.trim();
    queryParams.model = queryParams.model.trim();
    queryParams.vinNos = cleanEmptyEleArray(queryParams.vinNos);
    queryParams.commissionNos = cleanEmptyEleArray(queryParams.commissionNos);
    queryParams.brandList = queryParams.brand ? [] : allBrands;
    API.getSiccoTable(queryParams).then((res) => {
      tableData.value = res.content;
      total.value = res.totalElements;
      queryParams.size = res.size;
      const customerColumn = [
        'model',
        'modelYear',
        'dealerNameCn',
        'customerName',
        'specialUsage',
        'brand',
        'retailDate'
      ];
      tableData.value.length && Object.assign(columns, setColumnWidth(columns, tableData.value, customerColumn));
    });
  };

  const handleGetSiccoOverview = async () => {
    overview.value = await API.getSiccoOverview(queryParams.brand ? [queryParams.brand] : allBrands);
  };

  const resetSearchParams = () => {
    resetParam(queryParams);
    queryParams.current = 1;
    isOpenPanle.value = false;
    isOpen.value = false;
    activeKey.value = '';
  };

  const mulSearchEvent = () => {
    resetSearchParams();
    // 重置点击页面上的统计区域的标志
    queryParams.statisticsClicked = 0;
    queryParams.abnormalAreaClicked = 0;
    getTableData();
  };
  const pageChange = (page: number, pageSize: number) => {
    queryParams.current = page > 0 ? page : 1;
    queryParams.size = pageSize;
    getTableData();
  };
  const sizeChange = (page: number, pageSize: number) => {
    queryParams.current = page > 0 ? page : 1;
    queryParams.size = pageSize;
    getTableData();
  };

  const overviewSerachEvent = (str: string) => {
    isOpenPanle.value = false;
    isOpen.value = false;
    activeKey.value = '';
    resetEvent();
    // 点击了页面上的统计区域：1， 未点击统计区域：0
    queryParams.statisticsClicked = 1;
    queryParams.abnormalAreaClicked = 0;
    switch (str) {
      case 'leftRetialTotal':
        queryParams.retailStartDate = overview.value.currRetailMonth + '/01';
        break;
      case 'leftUnAuditTotal':
        queryParams.retailStartDate = overview.value.currRetailMonth + '/01';
        queryParams.checkStatus = '1112';
        break;
      case 'leftModifyUnAuditTotal':
        queryParams.retailStartDate = overview.value.currRetailMonth + '/01';
        queryParams.checkStatus = '1113';
        break;
      case 'leftCheckStatusReturnedCar':
        // 退车按未点击统计区域统计（点击了页面上的统计区域不统计退车数据）
        queryParams.statisticsClicked = 0;
        queryParams.retailStartDate = overview.value.currRetailMonth + '/01';
        queryParams.checkStatus = '4444';
        break;
      case 'rightRetialTotal':
        queryParams.retailStartDate = overview.value.lastRetailMonth + '/01';
        break;
      case 'rightUnAuditTotal':
        queryParams.retailStartDate = overview.value.lastRetailMonth + '/01';
        queryParams.checkStatus = '1112';
        break;
      case 'rightModifyUnAuditTotal':
        queryParams.retailStartDate = overview.value.lastRetailMonth + '/01';
        queryParams.checkStatus = '1113';
        break;
      case 'rightCheckStatusReturnedCar':
        // 退车按未点击统计区域统计（点击了页面上的统计区域不统计退车数据）
        queryParams.statisticsClicked = 0;
        queryParams.retailStartDate = overview.value.lastRetailMonth + '/01';
        queryParams.checkStatus = '4444';
        break;
      case 'abnormalRetialTotal':
        queryParams.retailStartDate = '';
        queryParams.abnormalAreaClicked = 1;
        break;
      case 'abnormalUnAuditTotal':
        queryParams.retailStartDate = '';
        queryParams.checkStatus = '1112';
        queryParams.abnormalAreaClicked = 1;
        break;
      case 'abnormalModifyUnAuditTotal':
        queryParams.retailStartDate = '';
        queryParams.checkStatus = '1113';
        queryParams.abnormalAreaClicked = 1;
        break;
      case 'abnormalCheckStatusReturnedCar':
        // 退车按未点击统计区域统计（点击了页面上的统计区域不统计退车数据）
        queryParams.statisticsClicked = 0;
        queryParams.retailStartDate = '';
        queryParams.checkStatus = '4444';
        queryParams.abnormalAreaClicked = 1;
        break;
    }
    queryParams.retailEndDate = queryParams.retailStartDate ? getMonthLast(queryParams.retailStartDate) : '';
    resetSearchParams();
    getTableData();
  };

  const exportResult = () => {
    queryParams.brandList = queryParams.brand ? [] : allBrands;
    const params = {
      url: `/rvapi/basic/approval/sicco/resultExport`,
      method: 'post',
      params: queryParams,
      fileName: `${+new Date()}.xlsx`
    };
    downloadFile(params);
  };
  const handleClick = (event: MouseEvent) => {
    isOpenPanle.value = false;
    activeKey.value = '';
    event.stopPropagation();
  };
  const handleActiveKey = () => {
    if (activeKey.value.indexOf('4') !== -1) {
      showCollapsed.value = true;
    } else {
      showCollapsed.value = false;
    }
  };
  const textContentNo = ref<string>('');
  const textNoArray = ref<string[]>([]);
  const batchPlaceholder = ref(['', '', '', '', '', '']);
  // 设置批量查询modal 的 textNoArray
  const setBatchQueryTextNoArray = () => {
    if (batchQuery.batchQueryTitle === '车架号批量查询') {
      textNoArray.value =
        cleanEmptyEleArray(queryParams.vinNos)[0] === '' ? [] : cleanEmptyEleArray(queryParams.vinNos);
    } else {
      textNoArray.value =
        cleanEmptyEleArray(queryParams.commissionNos)[0] === '' ? [] : cleanEmptyEleArray(queryParams.commissionNos);
    }
  };
  // 设置批量查询modal 的 占位div
  const setBatchPlaceholder = () => {
    if (textNoArray.value.length) {
      batchPlaceholder.value = [];
    } else {
      batchPlaceholder.value = ['', '', '', '', '', ''];
    }
  };
  // 批量查询按钮事件
  const batchQueryEvent = (arg: string) => {
    if (arg === 'vinNo') {
      batchQuery.batchQueryModal = true;
      batchQuery.batchQueryTitle = '车架号批量查询';
      batchQuery.batchQueryListTitle = '车架号列表';
    } else {
      batchQuery.batchQueryModal = true;
      batchQuery.batchQueryTitle = '生产编号批量查询';
      batchQuery.batchQueryListTitle = '生产编号列表';
    }
    setBatchQueryTextNoArray();
    setBatchPlaceholder();
    const dom = document.getElementsByClassName('list-content')[0];
    nextTick(() => {
      dom && dom.scrollTo(0, textNoArray.value.length * 36);
      input.value.focus();
    });
  };
  // 批量文本内容
  const textChangEvent = () => {
    if (textContentNo.value.length < 5) {
      message.error('请从Excel中粘贴到文本框');
      textContentNo.value = '';
      return;
    }
    textNoArray.value = textNoArray.value.concat(
      textContentNo.value
        .replace(/\r|\n|\s/g, ',')
        .split(',')
        .filter((item) => item)
    );
    const dom = document.getElementsByClassName('list-content')[0];
    textContentNo.value = '';
    if (batchQuery.batchQueryTitle === '车架号批量查询') {
      queryParams.vinNos = []; // 避免多次打开Modal 有重复元素
      queryParams.vinNos = queryParams.vinNos.concat(textNoArray.value);
    } else {
      queryParams.commissionNos = []; // 避免多次打开Modal 有重复元素
      queryParams.commissionNos = queryParams.commissionNos.concat(textNoArray.value);
    }
    setBatchPlaceholder();
    nextTick(() => {
      dom.scrollTo(0, dom.scrollHeight + 40);
      input.value.focus();
    });
  };
  // 关闭批量查询Modal
  const closeBatchQueryModal = () => {
    batchQuery.batchQueryModal = false;
    queryParams.vinNo = queryParams.vinNos[0];
    queryParams.commissionNo = queryParams.commissionNos[0];
  };
  // 清除批量查询
  const clearBatchQuery = () => {
    if (batchQuery.batchQueryTitle === '车架号批量查询') {
      queryParams.vinNos = [''];
    } else {
      queryParams.commissionNos = [''];
    }
    setBatchQueryTextNoArray();
    setBatchPlaceholder();
    input.value.focus();
  };

  const dateChangeEvent = (date: string[], arg: string) => {
    switch (arg) {
      case 'retail':
        queryParams.retailStartDate = date[0];
        queryParams.retailEndDate = date[1];
        break;
      case 'invoice':
        queryParams.invoiceStartDate = date[0];
        queryParams.invoiceEndDate = date[1];
        break;
      case 'license':
        queryParams.licenseStartDate = date[0];
        queryParams.licenseEndDate = date[1];
        break;
    }
  };

  let timeout: any;
  const searchEvent = (params: any, flag: string) => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    const sendRequest = () => {
      // dealerName有值 发送请求
      if (params.dealerName) {
        getDealerName({ params }).then((res) => {
          switch (flag) {
            case 'C':
              res.forEach((item) => {
                dealerNameCnArray.value.push({
                  value: item
                });
              });
              break;
            case 'E':
              res.forEach((item) => {
                dealerNameEnArray.value.push({
                  value: item
                });
              });
              break;
            case 'D':
              res.forEach((item) => {
                coficoArray.value.push({
                  value: item
                });
              });
              break;
            case 'S':
              res.forEach((item) => {
                swtArray.value.push({
                  value: item
                });
              });
              break;
          }
        });
      }
    };

    timeout = setTimeout(sendRequest, 2000);
  };
  // 标识位 C:中文名 E:英文名 D:dealerCode S：dealerSwtCode
  const handleSearch = (val: string, flag: string) => {
    const params = {
      dealerName: val,
      flag: flag
    };
    dealerNameCnArray.value = [];
    dealerNameEnArray.value = [];
    coficoArray.value = [];
    swtArray.value = [];
    searchEvent(params, flag);
  };

  const handleBatchDownload = (idList: number[]) => {
    resetParam(queryParams);
    queryParams.vinNo = queryParams.vinNo.trim();
    queryParams.commissionNo = queryParams.commissionNo.trim();
    queryParams.model = queryParams.model.trim();
    queryParams.vinNos = cleanEmptyEleArray(queryParams.vinNos);
    queryParams.commissionNos = cleanEmptyEleArray(queryParams.commissionNos);
    const params = {
      idList,
      ...queryParams
    };
    API.batchDownload(params, {
      headers: {
        'code-handler': 'ignore'
      }
    })
      .then((res: any) => {
        const downloadElement = document.createElement('a');
        downloadElement.href = res.downloadUrl;
        downloadElement.download = res.docName;
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
      })
      .catch((e) => {
        switch (e.response.data.code) {
          case '90012':
            message.warning(e.response.data.message);
            break;
          default:
            message.error(e.response.data.message);
            break;
        }
      });
  };

  return {
    resetEvent,
    mulSearchEvent,
    pageChange,
    sizeChange,
    handleClick,
    handleActiveKey,
    overviewSerachEvent,
    closeBatchQueryModal,
    clearBatchQuery,
    textChangEvent,
    batchQueryEvent,
    handleSearch,
    exportResult,
    init,
    dateChangeEvent,
    dealerNameEnArray,
    swtArray,
    coficoArray,
    dealerNameCnArray,
    textContentNo,
    textNoArray,
    batchPlaceholder,
    columns,
    queryParams,
    overview,
    isOpenPanle,
    isOpen,
    tableData,
    total,
    activeKey,
    showCollapsed,
    checkStatusArray,
    approvalStatusArray,
    batchQuery,
    input,
    flagBlurSearch,
    cSelect,
    selectData,
    getRegionsbyBulist,
    handleBatchDownload,
    getTableData,
    handleGetSiccoOverview
  };
};
export default useSiccoOverview;
